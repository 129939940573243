import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { Context } from './types/context'

export const initializeSentry = ({ app }: Context): void => {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release:
      'connect-creation-com@' + import.meta.env.VERSION ? `v${import.meta.env.VERSION}` : 'dev',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })

  app.config.errorHandler = (err, vm, info) => {
    Sentry.setTag('info', info)
    Sentry.captureException(err)
  }

  window.addEventListener('error', (event) => {
    Sentry.captureException(event)
  })
  window.addEventListener('unhandledrejection', (event) => {
    Sentry.captureException(event)
  })
}
