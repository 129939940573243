import { FetchRPCClient, SimpleRPC, generateIDClient } from '@cmiteam/rpc/dist/client/index.js'
import { EventInterface } from '@/types/event'
import { SignUpRecord } from './types/signUpRecord'
import { Country } from './types/country'
import { LocalDate } from './types/localDate'
import { Office } from './types/office'

export class Api {
  rpcClient: any
  rpc: any

  constructor() {
    const apiUrl = import.meta.env.VITE_API_URL
    const apiKey = import.meta.env.VITE_API_KEY

    if (!apiUrl) throw new Error('VITE_API_URL is not set')
    if (!apiKey) throw new Error('VITE_API_KEY is not set')

    const encodedApiKey = btoa(apiKey)

    this.rpcClient = new FetchRPCClient({
      gatewayURL: apiUrl,
    })

    this.rpc = SimpleRPC.create(this.rpcClient, generateIDClient)

    this.rpcClient.setHeaders({ Authorization: `Basic ${encodedApiKey}:` })
  }

  async fetchEvents(office: Office, localDate: LocalDate): Promise<EventInterface[]> {
    const request = await fetch(`/api/events`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ office, localDate }),
    })

    return await request.json()
  }

  async saveRecord(signUpRecord: Partial<SignUpRecord>): Promise<SignUpRecord> {
    return await this.rpc.SignUp.saveRecord(signUpRecord)
  }

  async subscribeRecordToInfobytes(signUpRecord: Partial<SignUpRecord>): Promise<SignUpRecord> {
    return await this.rpc.SignUp.subscribeRecord(signUpRecord, ['infobytes'])
  }

  async lookupIp(): Promise<{ country_code: string }> {
    const request = await fetch(`/api/ip`)
    return await request.json()
  }

  async getCountryFromIp(): Promise<string> {
    const result = await this.lookupIp()

    return result.country_code.toLowerCase()
  }

  async fetchCountries(): Promise<Record<string, Country>> {
    const countriesFromGeo: {
      [country_code: string]: {
        name: string
        office_code: Office
        country_code: string
      }
    } = await this.rpc.Geo.getAllCountries()

    const countries: Record<string, Country> = {}

    Object.keys(countriesFromGeo).map((countryCode) => {
      const country = countriesFromGeo[countryCode]

      countries[countryCode] = {
        name: country.name,
        officeCode: country.office_code,
        code: country.country_code,
      }
    })

    return countries
  }
}

export const api = new Api()
